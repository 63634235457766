import Api from "@/services/Api";

export default {
  getUsers(params) {
    var queryString = Object.keys(params)
      .map(key => key + "=" + params[key])
      .join("&");
    if (queryString.length > 0) {
      queryString = "?" + queryString;
    }
    return Api().get("/api/users" + queryString);
  },
  getUser(userId) {
    return Api().get("/api/users/" + userId);
  },
  updateUser(userId, params) {
    return Api().put("/api/users/" + userId, params);
  },
  deleteUser(userId) {
    return Api().delete("/api/users/" + userId);
  },
  updateUsers(params) {
    return Api().post("/api/users/updateUsers", params);
  }
};
